

//.hover-editor-mode {
//
//  .content-editor-mode {
//    display: none;
//    width: 100%;
//    height: 100%;
//  }
//
//  &:hover .content-editor-mode {
//    display: flex;
//    background: rgba(0, 0, 0, 0.30);
//    background: red !important;
//  }
//
//
//}

.hover-editor-mode {
  position: relative; // Isso é usado apenas durante o hover

  &:hover:not(.canvas) {
    .content-editor-mode, /*editor-mode-hover*/ {
      position: absolute; // Posiciona a div interna
      top: 0; // Alinha ao topo da div pai
      left: 0; // Alinha à esquerda da div pai
      width: 100%; // Ocupa 100% da largura da div pai
      height: 100%; // Ocupa 100% da altura da div pai
      display: flex; // Usa flexbox para centralizar o conteúdo
      align-items: center; // Centraliza verticalmente
      justify-content: center; // Centraliza horizontalmente
      background-color: rgba(105, 105, 105, 0.5); // Cor de fundo semi-transparente
      z-index: 1; // Para garantir que fique acima do texto da div pai
    }
  }
}

.content-editor-mode, /*editor-mode-hover*/ {
  display: none; // Esconde a div por padrão

  // Mostra a div quando a div pai estiver em hover
  .hover-editor-mode:hover & {
    display: flex; // Exibe a div ao passar o mouse sobre a div pai
  }
}

@import "./variables";



.form-label {
  //color: grey;
  //font-size: 9pt;
  //font-weight: normal;
  //
  //&.label-title {
  //  padding-bottom: .25rem;
  //  margin-bottom: 0;
  //  font-weight: bolder;
  //  color: #1e1e1e;
  //  font-size: 12pt;
  //}
}

// Font Sizes - gera classes de fontes de 1 a 100
@for $i from 1 through 100 {
  .font-size-#{$i}, .fs-#{$i}, .f-s-#{$i} {
    font-size: #{$i}pt !important;
  }
  .h-#{$i} {
    height: #{$i}#{'%'};
  }
}

//@each $key, $color in $colors-theme {
//  .font-color-#{$key}, .fc-#{$key} {
//    color: $color;
//  }
//}
//@each $key, $color in $colors-theme-scale {
//  .font-color-#{$key}, .fc-#{$key} {
//    color: $color;
//  }
//}

.f-bold {
  font-weight: bolder !important;
}

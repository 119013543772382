body {
  /*************************************************
      ESTILOS COMUM A TODOS OS INPUTS
   ************************************************/

  // estilo de erro ao preencher input
  input.ng-touched, select.ng-touched, ng-autocomplete.ng-touched, textarea.ng-touched {
    &.ng-invalid {
      border: solid 1px red !important;
    }
  }

  .mat-select-panel, .cdk-global-overlay-wrapper, .cdk-overlay-container {
    z-index: 9999 !important;
  }

  .mat-form-field-appearance-outline:not(.preserve-style-material) {
    .mat-form-field-infix {
      padding: .2rem !important;
      margin: .2rem !important;
    }
  }

  .form-control, .form-select {
    border: 1px solid rgba(128, 128, 128, 0.25);
  }

  .input-title {
    .form-label {
      padding-bottom: 0;
      margin-bottom: 0;
      font-weight: bolder;
      color: #1e1e1e;
      font-size: 10pt;
    }
  }

  .theme-input-group {
    .form-control {
      border-right: none;
    }

    .input-group-append {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(128, 128, 128, 0.25);
      border-left: none;
      border-radius: 0 6px 5px 0;

      i {
        font-size: 10pt;
        padding: .25rem;
        margin: .1rem .3rem .1rem .1rem;
        background: var(--dg-theme-primary-300);
        border-radius: 25px;
        color: white;
      }
    }
  }

}

.filter-table-header-root {
  .filter-ul-order {
    list-style-type: none;
    padding: 0;

    li {
      padding: .25rem;

      &.filter-li-order {
        > div {
          width: 50%;
          display: inline-flex;
          justify-content: center;
          cursor: pointer;
          border-radius: 5px;

          &:hover {
            background-color: var(--dg-theme-primary-200);
          }
        }
      }

      &.disabled {
        cursor: default !important;
        opacity: .7;
      }
    }
  }

  .theme-list-box-autocomplete2 {
    background-color: #efe5ff;
    border-color: #7749f8;


    .theme-ul-items-autocomplete2, .theme-list-selected-body {
      border: #7749f8 solid 1px;
      border-radius: 5px;
      background-color: white;

      .theme-li-items-autocomplete2 {
        padding: .5rem;
      }

      .theme-li-items-autocomplete2:not(:last-child) {
        border-bottom: #a182f8 solid 0.1rem;
      }
    }
  }
}
